<template>
    <auth-page-template heading="Set a New Password">
        <template #subSection>
            <p class="text-center">Please enter your new password</p>
        </template>
        <div>
            <div>
                <validated-input type="password" label="Enter new password">
                    <template #append>
                        <img src="../../assets/web/auth/password-lock.svg" alt="">
                    </template>
                </validated-input>
            </div>
            <div>
                <validated-input type="password" label="Confirm new password">
                    <template #append>
                        <img src="../../assets/web/auth/password-lock.svg" alt="">
                    </template>
                </validated-input>
                <p class="fs--2 text-4">Password must contain atleast 8 characters </p>
            </div>
            <div>
                <btn design="" block class="btn-bg-white mt-4" size="lg" border-radius="4" text="Submit"></btn>
            </div>
        </div>

    </auth-page-template>
</template>

<script>
export default {
    name: 'SetNewPassword'
};
</script>

<style scoped>

</style>
